import * as React from "react"

import Layout from "../../components/Layout"
import HeroImageAbout from "../../components/AboutPage/HeroImageAbout"
import Thanks from "../../components/Newsletter/Thanks"
import SentimentPageNewsletter from "../../components/LuxurySentimentPage/SentimentPageNewsletter"


function publicspeakingPage({ data }) {
  return (
    <Layout>
      <Thanks />

    </Layout>
  )
}

export default publicspeakingPage
